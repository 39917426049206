import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import queryString from 'query-string';
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.minimal.css';
import { useHistory, useLocation } from "react-router-dom";

import useDimension from '../../../../customHooks/useDimension'
import usePrevious from '../../../../customHooks/usePrevious'
import { useGlobalContext } from '../../../GlobalContext'
import { getProductsFiltered, getListFilters, setProductsFilters, resetSelectedProduct } from '../../../../actions/products'
import { createOrder, updateOrder } from '../../../../actions/orders'


import Loader from '../../../partials/Loader'
import { PRODUCTS_PER_REQUEST } from '../../../../constant'

import InputField from '../../../partials/form_fields/InputField'
import LineProduct from './LineProduct'
import Select, { components } from 'react-select';
import selectStyle from '../../../partials/form_fields/selectStyle'

import IconSearch from '../../../../assets/images/icons/icon-search.svg'

const Zoom = cssTransition({
  enter: 'zoomInToast',
  exit: 'zoomOutToast',
  duration: 600,
  appendPosition: false
});

export default function Products() {
  const [context, dispatch] = useGlobalContext();
  const listProducts = useRef();
  const productsRef = useRef();
  const firstMount = useRef(true);
  const history = useHistory();
  const location = useLocation();

  const dimension = useDimension();

  const notifyAdd = () => toast("Le produit a bien été ajouté", { autoClose: 2000, transition: Zoom, className: "success" });
  const notifyDelete = () => toast("Le produit a bien été supprimé", { autoClose: 2000, transition: Zoom, className: "success" });
  const notifyLoading = () => toast("Modification en cours", { autoClose: 2000, transition: Zoom, className: "loading" });

  const productsReducer = context.productsReducer
  const authReducer = context.authReducer

  //ACTIONS
  const _getProductsFiltered = (data) => getProductsFiltered(dispatch, data)
  const _setProductsFilters = (filters) => setProductsFilters(dispatch, filters)
  const _createOrder = (order) => createOrder(dispatch, order)
  const _updateOrder = (order, id) => updateOrder(dispatch, order, id)
  const _getListFilters = () => getListFilters(dispatch)
  const _resetSelectedProduct = () => resetSelectedProduct(dispatch)

  const [filteredProducts, setFilteredProducts] = useState()
  const [countProducts, setCountProducts] = useState()
  const [orderByCompany, setOrderByCompany] = useState()
  const [orderConfirmedByCompany, setOrderConfirmedByCompany] = useState()

  const [sort, setSort] = useState({})
  const [search, setSearch] = useState()
  const [defaultSearch, setDefaultSearch] = useState()
  const [productsType, setProductsType] = useState()
  const [selectedProductsType, setSelectedProductsType] = useState()

  const [productsProviders, setProductsProviders] = useState()
  const [selectedProductsProvider, setSelectedProductsProvider] = useState()

  const [positionSortBy, setPositionSortBy] = useState()
  const [sortByFixed, setSortByFixed] = useState()

  const [countOrderedProducts, setCountOrderedProducts] = useState()
  const prevCountOrderedProducts = usePrevious(countOrderedProducts)

  const prevLocationSearch = usePrevious(location.search)
  const [firstLoadDone, setFirstLoadDone] = useState(false)

  //TIMESTAMP FOR DESACTIVATE INFINITE SCROLL
  const [timestamp, setTimestamp] = useState(Date.now())

  const [hitBottom, setHitBottom] = useState(false)

  function getIfTouchDevice() {
    const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
    return supportsTouch;
  }

  useEffect(() => {
    if (!filteredProducts || positionSortBy) return;
    let target = document.querySelector('.container-header-list')
    if (target) setPositionSortBy(target.offsetTop)
  }, [filteredProducts])

  useEffect(() => {
    if (!filteredProducts) return;
    let target = document.querySelector('.container-header-list')
    if (target) setPositionSortBy(target.offsetTop)
  }, [dimension.width])

  //ONE MOUNT SET FILTERS
  // FROM PRODUCTS REDUCERS FILTERS
  useEffect(() => {
    setSearch(productsReducer.filters.search)
    setDefaultSearch(productsReducer.filters.search)
    setSort(productsReducer.filters.sort)
    setSelectedProductsType(productsReducer.filters.type)
    setSelectedProductsProvider(productsReducer.filters.provider)
  }, [])

  //INIT QUERY SEARCH TO 1 IF NO PAGE SPECIFIED
  useEffect(() => {
    let search = queryString.parse(location.search)
    let prevSearch = queryString.parse(prevLocationSearch)
    if (prevSearch.page && search.page) {
      if (parseInt(prevSearch.page) > parseInt(search.page)) {
        productsRef.current.scrollTo(0, 0)
      }
    }

    if (!search.page) {
      history.push({
        pathname: location.pathname,
        search: productsReducer.filters.page ? `?page=${productsReducer.filters.page}` : `?page=1`
      })
    }
  }, [location.search])

  // FOCUS ON PRODUCT IF WE CAME FROM DETAIL PRODUCT
  useEffect(() => {
    if (productsReducer.product && firstLoadDone) {
      let target = document.getElementById(`product-${productsReducer.product._id}`)
      let { current } = listProducts
      if (target && current) {
        target.classList.add("prev-selected")
        let listBound = current.getBoundingClientRect()
        let targetBound = target.getBoundingClientRect()
        let scrollY = -(listBound.y - targetBound.y)
        productsRef.current.scrollTo(0, scrollY)
      }
    }
  }, [productsReducer.product, firstLoadDone])

  //WHEN A FILTERS STATE CHANGE SAVE IT
  // IN PRODUCTS REDUCERS
  useEffect(() => {
    let querySearch = queryString.parse(location.search)
    let page = querySearch.page
    if (!page) return;
    _setProductsFilters({
      search: search ? search : null,
      sort: sort ? sort : {},
      type: selectedProductsType ? selectedProductsType : null,
      provider: selectedProductsProvider ? selectedProductsProvider : null,
      page: parseInt(page),
    })
  }, [
    sort,
    selectedProductsType,
    selectedProductsProvider,
    search,
    location.search
  ])


  // INIT DROPDOWNS FILTERS
  useEffect(() => {
    if (!productsReducer.listFilters) {
      _getListFilters()
    } else {
      if (productsReducer.listFilters.products) {
        const sortedProductsType = productsReducer.listFilters.products.sort((a, b) => a.label.localeCompare(b.label));
        setProductsType(sortedProductsType);
      }
      if (productsReducer.listFilters.companies) {
        const sortedProductsProviders = productsReducer.listFilters.companies.sort((a, b) => a.label.localeCompare(b.label));
        setProductsProviders(sortedProductsProviders);
      }
    }
  }, [productsReducer.listFilters])

  // IF WE LEAVE PRODUCTS LIST
  // FOR AN OTHER PAGE THAN PRODUCT PAGE
  // CLEAR SAVED FILTERS
  useEffect(() => {
    let unlisten = history.listen((location) => {
      if (!/\/products/.test(location.pathname)) {
        _resetSelectedProduct()
        _setProductsFilters({
          search: null,
          sort: {},
          type: null,
          provider: null,
          page: 1
        })
      }
    })
    return () => {
      unlisten()
    }
  }, [])

  //COUNT ORDERED PRODUCTS
  useEffect(() => {
    if (productsReducer.orders) {
      let count = 0
      let allProducts = productsReducer.orders.flatMap((order) => {
        return order.products
      })
      count = allProducts.length

      setCountOrderedProducts(count)
    }
  }, [productsReducer.orders])

  //GET PRODUCTS ON FILTERS OR PAGE CHANGE
  useEffect(() => {
    if (!productsReducer.filters.page) return;
    let number;
    let page;
    let products = null;
    let companies = null;

    if (firstMount.current) {
      number = PRODUCTS_PER_REQUEST * productsReducer.filters.page;
      page = 1;
      setFirstLoadDone(false)
    } else {
      number = PRODUCTS_PER_REQUEST;
      page = productsReducer.filters.page;
    }

    if (productsReducer.filters.type && productsReducer.filters.type.length > 0) {
      products = productsReducer.filters.type.map(d => d.value)
    }

    if (productsReducer.filters.provider && productsReducer.filters.provider.length > 0) {
      companies = productsReducer.filters.provider.map(d => d.value)
    }

    _getProductsFiltered({
      search: productsReducer.filters.search,
      orderBy: productsReducer.filters.sort,
      companies,
      products,
      page,
      number,
    })
    firstMount.current = false
  }, [
    productsReducer.filters.sort,
    productsReducer.filters.type,
    productsReducer.filters.provider,
    productsReducer.filters.search,
    productsReducer.filters.page,
  ])

  //SET FILTERED PRODUCTS WHEN productsReducer.products CHANGE
  useEffect(() => {
    if (!productsReducer.products) return;
    if (!firstLoadDone) setFirstLoadDone(true)
    setFilteredProducts(productsReducer.products)
    setHitBottom(false)
  }, [productsReducer.products])

  //TOAST
  useEffect(() => {
    if (countOrderedProducts < prevCountOrderedProducts) {
      notifyDelete()
    } else if (countOrderedProducts > prevCountOrderedProducts) {
      notifyAdd()
    }
  }, [countOrderedProducts, prevCountOrderedProducts])

  useEffect(() => {
    if (!productsReducer.orders) return;
    let products = []
    let orderByCompany = {}
    let orderConfirmedByCompany = {}

    productsReducer.orders.forEach((order) => {
      let orderId = order._id

      let countBy = _.countBy(order.products, "_id")
      for (let id in countBy) {
        let findProduct = order.products.find((d) => d._id === id)
        products.push({ ...findProduct, count: countBy[id] })
      }

      if (order.status === 'pending') {
        orderByCompany[order.products[0].company._id] = orderId


      } else if (order.status === 'confirmed') {
        orderConfirmedByCompany[order.products[0].company._id] = orderId
      }
    })

    setOrderConfirmedByCompany(orderConfirmedByCompany)
    setOrderByCompany(orderByCompany)
    setCountProducts(products)

  }, [productsReducer.orders])


  function fnUpdateOrder(orderId, product, count, callback) {
    let order = productsReducer.orders.find((order) => order._id === orderId)
    let updateOrder = _.cloneDeep(order)

    updateOrder.products = updateOrder.products.filter((p) => p._id !== product._id)
    updateOrder.products = [...updateOrder.products, ...new Array(count).fill(product, 0, count)]

    let obj = {
      products: updateOrder.products,
      company: authReducer.user.company,
      user: authReducer.user._id,
      status: "pending"
    }

    // notifyLoading()
    _updateOrder(obj, orderId).then(() => {
      if (callback) callback()
    })

  }

  function fnCreateOrder(product, count) {
    let arrProducts = new Array(count).fill(product, 0, count);

    let obj = {
      products: arrProducts,
      company: authReducer.user.company,
      user: authReducer.user._id
    }

    // notifyLoading()
    _createOrder(obj)

  }

  function filterProductsType(val) {
    setSelectedProductsType(val)
    productsRef.current.scrollTo(0, 0)
    history.push({
      pathname: location.pathname,
      search: `?page=1`
    })
  }

  function filterProductsProvider(val) {
    setSelectedProductsProvider(val)
    productsRef.current.scrollTo(0, 0)
    history.push({
      pathname: location.pathname,
      search: `?page=1`
    })
  }

  function handleSearch(val) {
    // let value = val.toLowerCase();
    setSearch(val)
    productsRef.current.scrollTo(0, 0)
    history.push({
      pathname: location.pathname,
      search: `?page=1`
    })
  }

  function sortProducts(key, order) {
    let obj = { key }
    if (!order) {
      if (sort.key === key && sort.order === "desc") {
        setSort({})
      } else if (sort.key === key && sort.order === "asc") {
        obj.order = "desc"
        setSort(obj)
      } else if (sort.key !== key) {
        obj.order = "asc"
        setSort(obj)
      }
    } else {
      if (sort.key === key && sort.order === order) {
        setSort({})
      } else {
        obj.order = order
        setSort(obj)
      }
    }
    productsRef.current.scrollTo(0, 0)
    history.push({
      pathname: location.pathname,
      search: `?page=1`
    })
  }

  function fnStyleSelect() {
    return (
      {
        ...selectStyle,
        multiValue: (provided, state) => ({
          ...provided,
          fontSize: "14px",
          fontFamily: "Montserrat-Regular",
          backgroundColor: "#005FBF",
          color: "white"
        }),
        multiValueLabel: (provided, state) => ({
          ...provided,
          color: "white"
        }),
      }
    )
  }

  function scroll(e) {

    if (productsReducer.totalCount === productsReducer.products.length) return;

    if (/MenuList$/.test(e.target.className) || /menu$/.test(e.target.parentNode.className)) return

    let target = document.querySelector('.container-header-list')

    if (!sortByFixed &&
      e.target.scrollTop >= positionSortBy
    ) {
      setSortByFixed(true)
      target.classList.add('fixed')
    } else if (
      sortByFixed &&
      e.target.scrollTop < positionSortBy
    ) {
      setSortByFixed(false)
      target.classList.remove('fixed')
    }
  
    let { current } = listProducts
    let isHittingBottom = productsRef.current.getBoundingClientRect().bottom >= parseInt(current.getBoundingClientRect().bottom) + 30;

    // let hitBottom = current.getBoundingClientRect().bottom+30 === window.innerHeight
    let delay = getIfTouchDevice() ? 500 : 100
    if (isHittingBottom && (Date.now() - timestamp > delay)) {
      let pathname = location.pathname
      let search = queryString.parse(location.search)
      let page = search.page ? parseInt(search.page) + 1 : 2
      setTimestamp(Date.now())
      setHitBottom(true)
      history.push({
        pathname,
        search: `?page=${page}`
      })
    }

  }

  function renderListProducts() {
    if (!filteredProducts || !countProducts) return;
    let list = filteredProducts.map((product, i) => {
      let findProduct = countProducts.find((d) => d._id === product._id)
      let p = findProduct ? findProduct : product
      if (p._id === '630ddfdd6e51b59078fcfcf2') {
        console.log(p);
      }
      return (
        <li key={`products-${i}`}>
          <LineProduct
            orderConfirmedByCompany={orderConfirmedByCompany}
            orderByCompany={orderByCompany}
            fnUpdateOrder={fnUpdateOrder}
            fnCreateOrder={fnCreateOrder}
            product={p}
            count={p.count ? p.count : 0}
          />
        </li>
      )
    })

    return (
      <>
        <ul style={{}} ref={listProducts}>
          {list}
        </ul>
        {(
          productsReducer.products &&
          productsReducer.totalCount > 0 &&
          productsReducer.totalCount === productsReducer.products.length
        ) &&
          <div className="indicator-end"></div>
        }
        {hitBottom && <div className="scroll-loader"><Loader /></div>}
      </>

    )

  }

  return (
    <>
      <div className="products" onScroll={scroll} ref={productsRef}>
        <div className="content large">

          <div className="title-page">
            <h2>Offres salon</h2>
          </div>
          <p className="more">Cliquez sur le produit pour plus de détail.</p>
          <p className="hide-on-tablet">
            Vous trouverez ci-dessous l'ensemble des offres salon.<br />
            Préparez vos commandes en indiquant les quantités désirées <b>en colis ou en box</b>. Lors des jours de salon, retrouvez dans votre panier les produits "présélectionnés". Vous pourrez ainsi modifier et valider votre commande. Bonnes affaires...<br />
            Sans validation <b>sur le stand</b> du fournisseur, votre commande ne pourra pas être prise en compte.
          </p>

          {filteredProducts ?
            <div className="list-products">
              <div className="container-header-list">
                <div
                  className={"filters"}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      e.target.blur();
                      console.log("blur");
                    }
                  }}
                >
                  <div className="field-search">
                    <InputField
                      id={"search"}
                      title={""}
                      placeholder={"Recherche"}
                      type={"string"}
                      required={false}
                      defaultValue={defaultSearch}
                      handleChange={(val) => handleSearch(val)}
                      blurOnEnter={true}
                    />
                    <img src={IconSearch} alt="rechercher produits" />
                  </div>
                  {productsType &&
                    <Select
                      value={selectedProductsType}
                      onChange={(val) => filterProductsType(val)}
                      options={productsType}
                      isMulti={true}
                      styles={fnStyleSelect()}
                      placeholder={"Choisissez une famille de produits..."}
                    />
                  }
                  {productsProviders &&
                    <Select
                      value={selectedProductsProvider}
                      onChange={(val) => filterProductsProvider(val)}
                      options={productsProviders}
                      isMulti={true}
                      styles={fnStyleSelect()}
                      placeholder={"Choisissez un fournisseur..."}
                    />
                  }
                </div>
                <div className={"sortBy hide-on-tablet"}>
                  <div>
                    <p onClick={() => sortProducts("company.name")}>Fournisseurs</p>
                    <SortBy keyName={"company.name"} order={sort.key === "company.name" && sort.order} sort={sortProducts} />
                  </div>
                  <div className="product-segment">
                    <p onClick={() => sortProducts("segment")}>Segment</p>
                    <SortBy keyName={"segment"} order={sort.key === "segment" && sort.order} sort={sortProducts} />
                  </div>
                  <div className="product-gencod">
                    <p onClick={() => sortProducts("gencod")}>Gencod</p>
                    <SortBy keyName={"gencod"} order={sort.key === "gencod" && sort.order} sort={sortProducts} />
                  </div>
                  <div className="product-ref">
                    <p onClick={() => sortProducts("ref")}>Ref produit</p>
                    <SortBy keyName={"ref"} order={sort.key === "ref" && sort.order} sort={sortProducts} />
                  </div>
                  <div className="product-packing">
                    <p onClick={() => sortProducts("packing")}>Colisage</p>
                    <SortBy keyName={"packing"} order={sort.key === "packing" && sort.order} sort={sortProducts} />
                  </div>
                  <div className="product-price">
                    <p onClick={() => sortProducts("price")}>Prix net salon HT/unité</p>
                    <SortBy keyName={"price"} order={sort.key === "price" && sort.order} sort={sortProducts} />
                  </div>
                  <div>
                    <p>Nbre de colis</p>
                  </div>
                </div>
              </div>
              {renderListProducts()}
            </div> :
            <div className="loader"><Loader /></div>
          }

          {((productsReducer.filters.type || productsReducer.filters.provider || productsReducer.filters.search) &&
            productsReducer.totalCount === 0
          ) && (
              <p className="info-products">Aucun résultat ne correspond à votre recherche !</p>
            )}
          {(!productsReducer.isLoading && (filteredProducts && filteredProducts.length === 0) &&
            (!productsReducer.filters.type && !productsReducer.filters.provider && !productsReducer.filters.search)
          ) &&
            <p className="info-products">Les offres ne sont pas encore disponibles pour ce salon !</p>
          }
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        hideProgressBar={true}
      />
    </>
  )
}

const SortBy = ({ sort, order, keyName }) => {
  return (
    <div className="icon-sort" >
      <div className={order === "desc" ? "active" : ""} onClick={() => sort(keyName, "desc")}>
      </div>
      <div className={order === "asc" ? "active" : ""} onClick={() => sort(keyName, "asc")}>
      </div>
    </div>
  )
}
