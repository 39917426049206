import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useGlobalContext } from '../components/GlobalContext'
import useDispatch from './useDispatch'
import { getUser, getExhibition } from '../actions/auth'
import { getCompanyList, getCompany } from '../actions/company'
import { getOrders } from '../actions/orders'
// import { getProductsFlash } from '../actions/products'

export default function useInitCompany() {
  const [ context, dispatch ] = useGlobalContext();
  const authReducer = context.authReducer;
  const companyReducer = context.companyReducer;
  // const productsReducer = context.productsReducer;
  const history = useHistory();
  const [ type, setType ] = useState();

  //ACTIONS
  const _getExhibition = (id) => getExhibition(dispatch, id);
  const _getUser = (id) => getUser(dispatch, id);
  const _getCompanyList = useDispatch(getCompanyList, dispatch);
  const _getCompany = (id) => getCompany(dispatch, id);
  const _getOrders = (companyId) => getOrders(dispatch, companyId);
  // const _getProductsFlash = () => getProductsFlash(dispatch);

  useEffect(()=>{
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("token");
    const exhibition = localStorage.getItem("exhibition");
    

    if(!id || !token){
      history.push("/")
    }else if(!(authReducer.user && authReducer.user.profile)){
      _getExhibition(exhibition).then((res) => {
        _getUser(id);
        _getCompanyList();
      });
    }
  },[])

  useEffect(()=>{
    if (!authReducer.user ||
       !authReducer.user?.company  ||
       history.location.pathname === "/"
    ) return;

    if (!companyReducer.company){
      _getCompany(authReducer.user.company);
    }
  },[
    companyReducer.company,
    authReducer.user])

  useEffect(() => {
    if (!companyReducer.company ||
      !authReducer.user
    ) return;
    // HANDLE GET COMPANY ORDERS
    if (companyReducer.company.type === "magasin") {
      _getOrders(authReducer.user.company);
    }
    setType(companyReducer.company.type);
  }, [
    authReducer.user,
    companyReducer.isLoading,
  ]);

  // HANDLE GET PRODUCTS FLASH
  // useEffect(() => {
  //   if (!companyReducer.company ||
  //     !authReducer.user
  //   ) return;

  //   if (companyReducer.company.type === "magasin" &&
  //     !productsReducer.productsFlash
  //   ) {
  //     _getProductsFlash();
  //   }
  // }, [authReducer.user,
  //   companyReducer.isLoading,
  //   productsReducer.productsFlash,
  // ]);

  return ({
    activity : companyReducer.company ? companyReducer.company.activity : null,
    type : type,
    role : authReducer.user ? authReducer.user.role : null
  })


}
